<template>
    <div>
        <b-row>
            <b-col sm="8" xs="12">
                <h1>Användare</h1>
            </b-col>
            <b-col sm="4" xs="12" class="text-right" style="margin: auto">
                <b-button variant="primary" @click="add"> Lägg till </b-button>
            </b-col>
            <b-col md="7" sm="12" class="my-1">
                <b-form-group label-for="filter-input"
                              label-align-sm="right"
                              class="mb-0">
                    <b-input-group>
                        <b-form-input id="filter-input"
                                      v-model="filter"
                                      type="search"
                                      placeholder="Filtrera...." />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Rensa
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <b-col sm="12" md="5" class="my-1">
                <b-form-group label="Per sida"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-align-sm="right"
                              class="mb-0">
                    <PaginationPageSizeSelect id="per-page-select" v-model="perPage" />
                </b-form-group>
            </b-col>

            <b-col v-show="totalRows > perPage" cols="12" class="my-1 text-center">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="center"
                              class="my-0" />
            </b-col>
        </b-row>

        <!--TABLE-->
        <b-table :items="users"
                 :fields="fields"
                 :current-page="currentPage"
                 :per-page="perPage"
                 :filter="filter"
                 :filter-included-fields="['fullName', 'email', 'rolesAsString', 'userName']"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :sort-direction="sortDirection"
                 :busy="isBusy"
                 stacked="sm"
                 show-empty
                 small
                 @filtered="onFiltered"
                 @context-changed="onContextChanged">
            <template #cell(fullName)="row">
                <div class="overflow-hidden">
                    {{ row.item.fullName }}
                </div>
            </template>
            <template #cell(email)="row">
                <div class="overflow-hidden">
                    {{ row.item.email }}
                </div>
            </template>
            <template #cell(actions)="row">

                <b-button class="text-center d-block d-sm-none"
                          block
                          @click="edit(row.item)">
                    Mer
                </b-button>
                <b-button class="text-center d-block d-sm-none"
                          block
                          @click="changePassword(row.item)">
                    Byt lösenord
                </b-button>

                <!-- Open Edit sidebar. -->
                <font-awesome-icon class="d-none d-sm-inline text-secondary"
                                   icon="pen-square"
                                   size="lg"
                                   @click="edit(row.item)"
                                   role="button" />
                <!-- ./ Open Edit sidebar. -->
                <!-- Open Change password sidebar. -->

                <div class="d-none d-sm-inline-block ml-1">
                    <font-awesome-icon class="d-none d-sm-inline-block text-gray ml-1"
                                       icon="key"
                                       size="lg"
                                       @click="changePassword(row.item)"
                                       role="button"
                                       style="font-size: 1rem; top: -1px; position: relative; " />
                </div>
                <!-- ./ Open Change password sidebar. -->
            </template>
            <template #empty="">
                <h4 class="text-center" v-if="isSuperAdmin && !selectedOrganizationId">Ingen organisation vald</h4>
                <h4 class="text-center" v-else>Inga resultat</h4>
            </template>
            <template #emptyfiltered="">
                <div class="text-center">
                    <h4>Inga resultat</h4>
                    <h6>
                        <a href="#" @click="filter = ''">
                            Klicka här för att rensa aktiv filtrering
                        </a>
                    </h6>
                </div>
            </template>
            <template #table-busy>
                <div class="text-center mt-4">
                    <font-awesome-icon class="align-middle"
                                       icon="spinner"
                                       size="2x"
                                       spin />
                </div>
            </template>
        </b-table>

        <!--EDIT SIDEBAR-->
        <GenericSidebar :visible.sync="sidebar.visible"
                        :is-edit-mode.sync="sidebar.isEditMode"
                        :fields="[
                            { key: 'organizationName', label: 'Organisation' },
                            { key: 'firstName', label: 'Förnamn' },
                            { key: 'lastName', label: 'Efternamn' },
                            { key: 'email', label: 'E-post' },
                            { key: 'rolesAsString', label: 'Roller' },
                            { key: 'customerAsString', label: 'Kundbehörighet'}
                        ]"
                        :item="sidebar.selected"
                        @update:updateData="getData(user.organizationId)">
            <template v-slot:header>
                <h4>
                    <span v-if="sidebar.selected">
                        {{ sidebar.selected.fullName }}
                    </span>
                    <span v-if="sidebar.selected && sidebar.selected.id === '0'">
                        Skapa användare
                    </span>
                </h4>
            </template>
            <template v-slot:edit>
                <b-button v-if="!sidebar.isEditMode"
                          variant="secondary"
                          class="mb-2"
                          block
                          @click="sidebar.isEditMode = true">
                    Ändra
                </b-button>
                <SaveUser v-if="sidebar.isEditMode" :id="sidebar.selected.id" />
                <b-button v-if="!sidebar.isEditMode"
                          variant="danger"
                          class="mb-2"
                          block
                          @click="confirm('removeUser', sidebar.selected.id, `Du kommer ta bort användaren '${sidebar.selected.fullName}'`, )">
                    Ta bort
                </b-button>
            </template>
        </GenericSidebar>

        <!--SET PASSWORD MODAL-->
        <b-modal centered
                 title="Byt lösenord"
                 v-model="modalSetPassword.visible"
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
                 :hide-header-close="true">
            <template #modal-title>
                Byt lösenord för '{{modalSetPassword.selected.fullName}}'
            </template>
            <b-form-group label="Nytt lösenord"
                          label-for="new-password"
                          class="mb-0">
                <b-form-input id="new-password"
                              v-model="modalSetPassword.newPassword"
                              placeholder="Ange nytt lösenord"
                              type="password"
                              autofocus></b-form-input>
                <b-form-text id="input-organization-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Password')"
                            :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>
            <b-form-group label="Upprepa lösenord"
                          label-for="repeat-new-password"
                          class="mb-0">
                <b-form-input id="repeat-new-password"
                              v-model="modalSetPassword.confirmNewPassword"
                              placeholder="Repetera nytt lösenord"
                              type="password"></b-form-input>
                <b-form-text id="input-organization-help-block">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('ConfirmPassword')"
                            :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-form-text>
            </b-form-group>
            <b-alert :show="validationByKey('Other').length > 0"
                     variant="danger">
                <ul class="text-danger mb-0">
                    <li v-for="message in validationByKey('Other')" :key="message">
                        {{ message }}
                    </li>
                </ul>
            </b-alert>
            <template #modal-footer>
                <div class="d-flex justify-content-between">
                    <b-button variant="warning"
                              @click="modalSetPassword.newPassword = '';modalSetPassword.confirmNewPassword = ''; modalSetPassword.visible = false;modalSetPassword.validationErrors = null;">
                        Avbryt
                    </b-button>
                    <b-button variant="secondary"
                              class="ml-2"
                              type="submit"
                              @click="setPassword">Byt</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { get, remove, post } from '@/helpers/api';
    import SaveUser from '@/views/admin/manage/user/SaveUser';
    import GenericSidebar from '@/components/GenericSidebar';
    import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';

    export default {
        name: 'ListUsers',
        components: {
            GenericSidebar,
            SaveUser,
            PaginationPageSizeSelect
        },
        data() {
            return {
                modalSetPassword: {
                    visible: false,
                    selected: null,
                    newPassword: '',
                    validationErrors: null,
                },
                sidebar: {
                    selected: null,
                    visible: false,
                    isEditMode: false
                },
                allOrganizations: [],
                users: [],
                fields: [
                    {
                        key: 'fullName',
                        label: 'Namn',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'userName',
                        label: 'Användarnamn',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'email',
                        label: 'E-post',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    { key: 'actions', label: '', tdClass: 'table-list--actions--wrapper' }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                isBusy: true,
                isSuperAdmin: false
            };
        },
        computed: {
            ...mapState({
                user: (state) => state.user.user,
                selectedCustomer: (state) => state.user.selectedCustomer,
            }),
            sortOptions() {
                // Create an options list from our fields.
                return this.fields
                    .filter((f) => f.sortable)
                    .map((f) => {
                        return { text: f.label, value: f.key };
                    });
            },
            organizationId() {
                if (!this.user)
                    return null;

                return this.user.organizationId;
            }
        },
        watch: {
            organizationId: {
                handler(newValue) {
                    if (newValue > 0)
                        this.getData(newValue);
                },
                immediate: true
            },
            selectedCustomer: {
                handler(newValue) {
                    if (newValue)
                        this.getData();
                },
                immediate: true
            }
        },
        methods: {
            onContextChanged(ctx) {
                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            add() {
                this.sidebar = {
                    selected: {
                        id: '0'
                    },
                    visible: true,
                    isEditMode: true
                };
            },
            edit(item) {
                this.sidebar.selected = item;
                this.sidebar.visible = true;
            },
            changePassword(item) {
                this.modalSetPassword.selected = item;
                this.modalSetPassword.visible = true;
            },
            setPassword() {
                this.modalSetPassword.validationErrors = null;
                post(
                    'User',
                    `SetPassword?id=${this.modalSetPassword.selected.id}`,
                    {
                        password: this.modalSetPassword.newPassword,
                        confirmPassword: this.modalSetPassword.confirmNewPassword,
                        selectedCustomerId: this.selectedCustomer?.id ?? null
                    }
                ).then(x => {
                    this.modalSetPassword.newPassword = '';
                    this.modalSetPassword.confirmNewPassword = '';
                    this.modalSetPassword.visible = false;
                }).catch(x => {
                    this.modalSetPassword.validationErrors = x.response.data.errors
                });
            },
            validationByKey(code) {
                return this.modalSetPassword.validationErrors ? this.modalSetPassword.validationErrors[code] || [] : [];
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
            },
            getData() {
                let apiAddress = '';
                if (this.user.roles.includes('SuperAdmin') || this.user.roles.includes('Administrator'))
                    apiAddress = `GetAllByOrganizationId/${this.organizationId}`;
                else if (!this.selectedCustomer)
                    return;
                else
                    apiAddress = `GetAllByCustomerId/${this.selectedCustomer.id}`;

                get('User', apiAddress)
                    .then((x) => {
                        this.users = x.data;
                        // Set the initial number of items.
                        this.totalRows = this.users.length;
                        this.isBusy = false;
                    })
                    .catch((x) => {
                        this.$router.push({
                            name: 'error',
                            params: {
                                statusCode: null,
                                statusText: encodeURIComponent('Något gick snett')
                            }
                        })
                    });
            },
            async removeUser(id) {
                await remove('User', `Delete?id=${id}`)
                    .then((x) => {
                        // Reset and close sidebar.
                        this.sidebar.visible = false;
                        this.sidebar.selected = {
                            id: 0
                        };
                        // Remove item from list.
                        this.users.splice(
                            this.users.findIndex(x => x.id === id), 1
                        );
                        // Update total rows.
                        this.totalRows = this.users.length;
                    })
                    .catch((x) => {
                        alert('Det gick inte ta bort användaren. Försök igen.');
                    });
            },
            confirm(action, data, message) {
                this.$bvModal
                    .msgBoxConfirm(message, {
                        title: 'Är du säker?',
                        size: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed) this[action](data);
                    })
                    .catch((err) => {
                        // An error occurred
                    });
            },
        }
    };
</script>
